interface IProps{
    label: string;
    placeholder: string;
    textToTranslate?: string;
    setTextToTranslate: (text: string) => void;

}

function Input({label, placeholder, textToTranslate, setTextToTranslate}: IProps){

    return (
        <div className="flex flex-col justify-center items-center">
            <label className="p-2">{label}</label>
            <input className="w-5/6 h-12 rounded" placeholder={placeholder} value={textToTranslate} onChange={(e) => {setTextToTranslate(e.target.value)}}/>
        </div>
    )
}

export default Input;