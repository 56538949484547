interface IProps{
    label: string;
    placeholder: string;
    textToTranslate: string;
    setTranslation: (text: string) => void;
    human: boolean

}

function TranslatedInput({label, placeholder, textToTranslate, setTranslation, human}: IProps){

    let newString;
    if(human){
        newString = textToTranslate.replace(/a/g, "!");
        newString = newString.replace(/ą/g, "_");
        newString = newString.replace(/b/g, "☒");
        newString = newString.replace(/c/g, "%");
        newString = newString.replace(/ć/g, "✓");
        newString = newString.replace(/d/g, "☑");
        newString = newString.replace(/e/g, "ጥ");
        newString = newString.replace(/f/g, "@");
        newString = newString.replace(/g/g, "#");
        newString = newString.replace(/h/g, "™");
        newString = newString.replace(/i/g, "&");
        newString = newString.replace(/j/g, ",");
        newString = newString.replace(/k/g, "ä");
        newString = newString.replace(/l/g, ">");
        newString = newString.replace(/ł/g, "-");
        newString = newString.replace(/m/g, "±");
        newString = newString.replace(/n/g, "]");
        newString = newString.replace(/ń/g, "'");
        newString = newString.replace(/o/g, ";");
        newString = newString.replace(/ó/g, "¢");
        newString = newString.replace(/p/g, "£");
        newString = newString.replace(/q/g, ":");
        newString = newString.replace(/r/g, "`");
        newString = newString.replace(/s/g, "~");
        newString = newString.replace(/ś/g, "እ");
        newString = newString.replace(/t/g, "ፋ");
        newString = newString.replace(/u/g, "የ");
        newString = newString.replace(/v/g, "6");
        newString = newString.replace(/w/g, "መ");
        newString = newString.replace(/x/g, "8");
        newString = newString.replace(/y/g, "ነ");
        newString = newString.replace(/z/g, "ት");
        newString = newString.replace(/ź/g, "3");
        newString = newString.replace(/ż/g, "1");
        setTranslation(newString);
    }
    else{
        newString = textToTranslate.replace(/!/g, "a");
        newString = newString.replace(/_/g, "ą");
        newString = newString.replace(/☒/g, "b");
        newString = newString.replace(/%/g, "c");
        newString = newString.replace(/✓/g, "ć");
        newString = newString.replace(/☑/g, "d");
        newString = newString.replace(/ጥ/g, "e");
        newString = newString.replace(/@/g, "f");
        newString = newString.replace(/#/g, "g");
        newString = newString.replace(/™/g, "h");
        newString = newString.replace(/&/g, "i");
        newString = newString.replace(/,/g, "j");
        newString = newString.replace(/ä/g, "k");
        newString = newString.replace(/>/g, "l");
        newString = newString.replace(/-/g, "ł");
        newString = newString.replace(/±/g, "m");
        newString = newString.replace(/]/g, "n");
        newString = newString.replace(/'/g, "ń");
        newString = newString.replace(/;/g, "o");
        newString = newString.replace(/¢/g, "ó");
        newString = newString.replace(/£/g, "p");
        newString = newString.replace(/:/g, "q");
        newString = newString.replace(/`/g, "r");
        newString = newString.replace(/~/g, "s");
        newString = newString.replace(/እ/g, "ś");
        newString = newString.replace(/ፋ/g, "t");
        newString = newString.replace(/የ/g, "u");
        newString = newString.replace(/6/g, "v");
        newString = newString.replace(/መ/g, "w");
        newString = newString.replace(/8/g, "x");
        newString = newString.replace(/ነ/g, "y");
        newString = newString.replace(/ት/g, "z");
        newString = newString.replace(/3/g, "ź");
        newString = newString.replace(/1/g, "ż");
        setTranslation(newString);
    }



    return (
        <div className="flex flex-col justify-center items-center">
            <label className="p-2">{label}</label>
            <input className="w-5/6 h-12 rounded" placeholder={placeholder} value={newString}/>
        </div>
    )
}

export default TranslatedInput;