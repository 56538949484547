import { useState } from "react";
import Input from "./shared/components/Input";
import Layout from "./shared/components/Layout";
import TranslatedInput from "./shared/components/TranslatedInput";
import logo from "./shared/images/azyl.png";

function App() {
  const [state, setState] = useState(true);
  const [textToTranslate, setTextToTranslate] = useState("");
  const [translatedText, setTranslatedText] = useState("");

  let content;
  if (state) {
    content = (
      <>
        <Input
          placeholder=""
          label="Język Ludzki"
          textToTranslate={textToTranslate}
          setTextToTranslate={setTextToTranslate}
        ></Input>
        <TranslatedInput
          human={state}
          placeholder=""
          label="Język Azylski"
          textToTranslate={textToTranslate}
          setTranslation={setTranslatedText}
        ></TranslatedInput>
      </>
    );
  } else {
    content = (
      <>
        <Input
          placeholder=""
          label="Język Azylski"
          textToTranslate={textToTranslate}
          setTextToTranslate={setTextToTranslate}
        ></Input>
        <TranslatedInput
          human={state}
          placeholder=""
          label="Język Ludzki"
          textToTranslate={textToTranslate}
          setTranslation={setTranslatedText}
        ></TranslatedInput>
      </>
    );
  }

  return (
    <Layout>
      <div
        onClick={() => {
          setTextToTranslate("");
          setTranslatedText("");
          setState(!state);
        }}
        className="flex font-bold justify-center p-3 bg-green-300 mx-20 my-4 rounded hover:cursor-pointer hover:bg-green-400"
      >
        ZMIEŃ TRYB
      </div>
      {content}
      <div className="flex flex-col items-center justify-center m-10">
        <img src={logo} alt="logo" className="w-3/5 h-3/5" />
        Levitate144
      </div>
    </Layout>
  );
}

export default App;
