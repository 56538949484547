import {motion} from "framer-motion";

interface IProps{
    children: React.ReactNode;
}

function Layout({children}:IProps){

    return (
        <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }} className="flex flex-col h-screen w-screen items-center max-md:bg-gray-200">
            <h1 className="m-5 text-3xl md:text-5xl md:text-gray-300">AZYL TRANSLATOR</h1>
            <div className="bg-gray-200 h-full md:w-1/2 w-full my-8 rounded-xl">
                {children}
            </div>    
        </motion.div>
    )

}

export default Layout;